body.com_weather-tpl {
    .HAF-mainWrap {
        max-width: none;
        width: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;

        .HAF-content {
            margin: 1em;
        }

        .weatherLink-head {
            display: none;
        }

        .weatherLink-current {
            h3 {
                margin: 0;
            }

            .last-update {
                font-size: 0.9rem;
                font-style: italic;
                margin: 0;
            }
        }
    }
}
