/** Generated by FG **/
@font-face {
    font-family: 'Nothing You Could Do';
    src: url('../fonts/NothingYouCouldDo-Regular.eot');
    src: local('☺'), url('../fonts/NothingYouCouldDo-Regular.woff') format('woff'), url('../fonts/NothingYouCouldDo-Regular.ttf') format('truetype'), url('../fonts/NothingYouCouldDo-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face
{
    font-family: 'Open Sans Light';
    src: url('../fonts/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Light-webfont.svg#OpenSansLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: 'Open Sans Semibold';
    src: url('../fonts/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
